@charset "utf-8";
/* ==========================================================================
   Dark skin
   ========================================================================== */

/* Colors */
$background-color: #252a34 !default;
$text-color: #eaeaea !default;
$primary-color: #3269b9 !default;





@import "minimal-mistakes/skins/dark"; // skin
@import "minimal-mistakes"; // main partials



